import '@/css/app.scss';
import Masonry from 'masonry-layout';
import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';
import { searchBox, stats, hits, poweredBy, index, configure } from 'instantsearch.js/es/widgets';
import { highlight } from 'instantsearch.js/es/helpers';
import { connectHits } from 'instantsearch.js/es/connectors';

const initialData = (window as any).__INITIAL_DATA__;
const algoliaApplicationId = initialData.algoliaApplicationId;
const algoliaSearchApiKey = initialData.algoliaSearchApiKey;
const algoliaIndexVerhalen = initialData.algoliaIndexVerhalen;
const algoliaIndexVragen = initialData.algoliaIndexVragen;
const appId = algoliaApplicationId ?? 'G7240U7VPL';
const apiKey = algoliaSearchApiKey ?? '5f10d23eada62a7ca89b835ea5920e83';
const vragenIndexName = algoliaIndexVragen ?? 'vragen-en-antwoorden';
const verhalenIndexName = algoliaIndexVerhalen ?? 'verhalen';
const theToggle:HTMLButtonElement = document.querySelector('#toggle');
const searchButton:HTMLAnchorElement = document.querySelector('.menu-bar .search');
const searchClient = algoliasearch(appId, apiKey);
const searchBoxInput: HTMLDivElement = document.querySelector('.ais-search-box--input');
const allHitVragenTemplate: HTMLDivElement = document.querySelector('#all-hit-vragen-en-antwoorden-template');
const allHitVerhalenTemplate: HTMLDivElement = document.querySelector('#all-hit-verhalen-template');
const searchBoxContainer: HTMLDivElement = document.querySelector('#search-box');
const poweredByContainer: HTMLDivElement = document.querySelector('#powered-by');
const vragenContainer: HTMLDivElement = document.querySelector('#vragen-en-antwoorden-hits');
const verhalenContainer: HTMLDivElement = document.querySelector('#verhalen-hits');
const statsContainer: HTMLDivElement = document.querySelector('#stats-container');

const searchVragen = instantsearch({
    indexName: vragenIndexName,
    searchClient,
    future: {
        preserveSharedStateOnUnmount: true,
    },
});

const searchVerhalenIndex = index({ indexName: verhalenIndexName });

const vragenRenderHits = connectHits((renderOptions, isFirstRender) => {
    const { hits } = renderOptions;
    // const { widgetParams } = renderOptions;
    // const container = widgetParams.container;
    const container = vragenContainer;

    if (hits.length) {
        container.innerHTML = `
        <div class="ais-hits">
            <div class="inner">
                <h2>Vragen</h2>
                <ul class="ais-Hits-list">
                ${hits.map(item =>`
                    <li class="ais-Hits-item">
                        <div class="block block-vraag-list">
                            <a href="${item.uri}">
                                <h3>${highlight({ attribute: 'title', hit: item })}</h3>
                            </a>
                        </div>
                    </li>
                    `).join('')}
                </ul>
                <p><a href="/vragen-en-antwoorden">Alle vragen en antwoorden</a>.</p>
            </div>
        </div>
    `;
    } else {
        container.innerHTML = '';
    }
});

const verhalenRenderHits = connectHits((renderOptions, isFirstRender) => {
    const { hits } = renderOptions;
    // const { widgetParams } = renderOptions;
    // const container = widgetParams.container;
    const container = verhalenContainer;

    if (hits.length) {
        container.innerHTML = `
        <div class="ais-hits">
            <div class="inner">
                <h2>Verhalen</h2>
                <ul class="ais-Hits-list">
                ${hits.map(item =>`
                    <li class="ais-Hits-item">
                        <div class="block block-verhaal blockcolor-${item.bgColor}"">
                            <a href="${item.uri}">
                                <h3>${highlight({ attribute: 'title', hit: item })}</h3>
                                <h4>${item.auteur}</h4>
                            </a>
                        </div>
                    </li>
                    `).join('')}
                </ul>
                <p><a href="/verhalen">Alle verhalen</a>.</p>
            </div>
        </div>
      `;
    } else {
        container.innerHTML = '';
    }
});

const searchBoxWidget = searchBox({
    autofocus: false,
    container: searchBoxContainer,
    placeholder: 'Wat wil je weten?',
    showReset: false,
    showSubmit: false,
});

const statsWidget = stats({
    container: statsContainer,
    templates: {
        text(data, { html }) {
            if (data.hasManyResults || data.hasOneResult) {
                return html`
                <h4>Zoekresultaten voor ‘${data.query}’</h4>
                `;
            } else {
                return html`
                <h4>We konden niets vinden voor ‘${data.query}’</h4>
                <p>Zoek eens een keertje tussen alle <a href="/vragen-en-antwoorden">Vragen en antwoorden</a>.</p>
                `;
            }
        }
    }
});

const poweredByWidget = poweredBy({
  container: poweredByContainer,
  theme: 'dark',
});

// Add Widgets
searchVragen.addWidgets([
    configure({
        hitsPerPage: 10,
    }),
    searchBoxWidget,
    poweredByWidget,
    statsWidget,
    vragenRenderHits({}),
    searchVerhalenIndex.addWidgets([
        statsWidget,
        configure({
            hitsPerPage: 10,
        }),
        verhalenRenderHits({}),
    ])
]);

// Add extra listener to SearchboxInput
searchVragen.on('render', function() {
    const searchInput:HTMLInputElement = document.querySelector('.ais-SearchBox-input');
    const searchResult:HTMLElement = document.getElementById('search-results');

    if (searchInput) {
        searchInput.addEventListener('keyup', function(event) {
            if (searchResult !== null) {
                if (this && this.value == '') {
                    addClass(searchResult,'hide');
                } else {
                    removeClass(searchResult,'hide');
                    _openSearch();
                }
            }
        });
        
        if (searchInput && searchInput.value == '') {
            addClass(searchResult,'hide');
        }
    }
});

// InstantSearch start
searchVragen.start();

// Menu Toggle button
if (theToggle) {
    theToggle.onclick = function() {
        if (hasClass(document.body, 'search-open')) {
            _closeSearch();
        } else {
            toggleClass(this, 'open');
            toggleClass(document.body, 'menu-open');
        }
        return false;    
    }
}

if (searchButton) {
    searchButton.onclick = function(event) {
        event.preventDefault();
        _openSearch();
    }
}

function _openSearch() {
    addClass(theToggle, 'open');
    addClass(document.body, 'search-open');
}

function _closeSearch() {
    const searchInput:HTMLInputElement = document.querySelector('.ais-SearchBox-input');
    removeClass(theToggle, 'open');
    removeClass(document.body, 'search-open');
    if (searchInput && searchInput.value) {
      searchInput.value = '';
    }
}

// Masonry
// element argument can be a selector string
// for an individual element
function initMasonry() {
    const elem = document.querySelector('.content-blocks');
    if (elem) {
        const options = {
            itemSelector: '.block',
            columnWidth: '.grid-sizer',
            gutter: 8,
        };
        const msnry = new Masonry(elem, options);
    }
}

document.onreadystatechange = () => {
  if (document.readyState === "complete") {
    toggleClass(document.body, 'page-loaded');
    initMasonry();
  }
};

function getViewportWidth() {
    return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
}
// based on Todd Motto functions
// https://toddmotto.com/labs/reusable-js/

// hasClass
function hasClass(elem, className) {
	return new RegExp(' ' + className + ' ').test(' ' + elem.className + ' ');
}
// addClass
function addClass(elem, className) {
    if (!hasClass(elem, className)) {
    	elem.className += ' ' + className;
    }
}
// removeClass
function removeClass(elem, className) {
	var newClass = ' ' + elem.className.replace( /[\t\r\n]/g, ' ') + ' ';
	if (hasClass(elem, className)) {
        while (newClass.indexOf(' ' + className + ' ') >= 0 ) {
            newClass = newClass.replace(' ' + className + ' ', ' ');
        }
        elem.className = newClass.replace(/^\s+|\s+$/g, '');
    }
}
// toggleClass
function toggleClass(elem, className) {
    elem.classList.toggle(className);
}

/* Extend Poll */
// (function() {
//     "use strict";
     
//     $(".poll-form").submit( function(e) {

//         e.preventDefault();
    
//         var data = $(this).serialize();
    
//         $.ajax({
//             method: 'POST',
//             url: '/',
//             data: data,
//             headers: {'Content-Type': 'application/x-www-form-urlencoded'}
//         })
//             .success(function(data) {
//                 // console.log('success', data);

//                 var el = document.createElement( 'html' );
//                 el.innerHTML = data;

//                 var result = el.querySelector('.poll-results');
//                 console.log(result);

//                 $('.block-poll').empty().html(result);

//             })
//             .error(function(data) {
//                 console.log('error', data);
//             });
    
//     });

//     $('.poll-clear-button').on('click', function(e) {
//         var data = {
//             'action': 'polls/answers/deleteAnswer',
//             'id': 2,
//             [window.csrfTokenName]: window.csrfTokenValue,
//         };
//         e.preventDefault();
//         $.ajax({
//             method: 'POST',
//             url: '/',
//             data: data,
//         })
//             .success(function(data) {
//                 // console.log('success', data);

//                 var el = document.createElement( 'html' );
//                 el.innerHTML = data;

//                 var result = el.querySelector('.poll-results');
//                 console.log(result);

//             })
//             .error(function(data) {
//                 console.log('error', data);
//             });
//     });
   
// })();

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
      console.log("HMR")
    });
}